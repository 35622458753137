<template>
    <v-card style="height: 100%; min-height: 400px;" class="chart-card">
        <DarkOverlayLoader v-if="estadisticas.isLoading" />
        <v-card-text class="chart-card-scroller">
            <div class="chart-card-content">
                <apexchart type="bar" height="350" :options="options" :series="series" />
            </div>
        </v-card-text>
    </v-card>
</template>
<script>
import { createLoadable, setLoadableResponse, toggleLoadable } from '@/utils/loadable';
import DarkOverlayLoader from '@/components/utils/DarkOverlayLoader.vue';
import { toMoneyFormat } from '@/utils/data';

export default {
    name: 'EstadisticasPorModalidad',
    components: { DarkOverlayLoader },
    props: {
        eventoBusqueda: { type: String, required: true },
        listener: { type: Object, required: true },
    },
    data: () => ({
        estadisticas: createLoadable([]),
    }),
    computed: {
        modalidades() {
            return this.estadisticas.data.map((modalidad) => modalidad.codigo);
        },
        series() {
            const categorias = [
                { nombre: 'Monto planificado', key: 'monto_planificado' },
                { nombre: 'Monto proyectado', key: 'monto_proyectado' },
                { nombre: 'Monto certificado', key: 'monto_certificado' },
                { nombre: 'Monto adjudicado', key: 'monto_adjudicado' },
                { nombre: 'Monto contratado', key: 'monto_contratado' },
            ];

            const modalidades = this.estadisticas.data;

            return categorias.map((categoria) => ({
                name: categoria.nombre,
                data: modalidades.map((modalidad) => modalidad[categoria.key] ?? 0)
            }));
        },
        options() {
            const modalidades = this.estadisticas.data;

            return ({
                chart: {
                    type: 'bar',
                    height: 400,
                },
                title: {
                    text: 'Montos por modalidad de compra',
                },
                stroke: {
                    width: 1,
                    colors: ['#fff']
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                    },
                },
                xaxis: {
                    categories: this.modalidades,
                },
                fill: {
                    opacity: 1
                },
                yaxis: {
                    labels: {
                        formatter: (valor) => {
                            return toMoneyFormat(valor);
                        },
                    }
                },
                legend: {
                    position: 'top',
                    horizontalAlign: 'left'
                },
                dataLabels: {
                    enabled: false
                },
                tooltip: {
                    x: {
                        formatter: (value) => {
                            const modalidad = modalidades.find((_modalidad) => _modalidad.codigo === value);

                            return modalidad.nombre;
                        }
                    },
                    shared: true,
                    intersect: false,
                },
            });
        },
    },
    methods: {
        // UI
        manejarEventos(evento, payload) {
            if (evento !== this.eventoBusqueda) return;

            this.cargarEstadisticas(payload);
        },
        // DATA
        async cargarEstadisticas(filtros) {
            toggleLoadable(this.estadisticas);
            const { data } = await this.services.ReporteriaModalidadesCompra.cargarEstadisticasPorModalidad(filtros);
            setLoadableResponse(this.estadisticas, data);
        }
    },
    created() {
        this.cargarEstadisticas();
        this.listener.setHandler(this.manejarEventos);
    },
}
</script>