<template>
    <v-container>
        <v-card>
            <v-card-title >
                <div class="d-flex align-center" style="gap: 16px;">
                    <NavButton />
                    <h2 class="text-h6 text-uppercase font-weight-bold">Reportería modalidades de compra</h2>
                </div>
            </v-card-title>

            <v-divider />

            <v-card-text class="px-4 py-4">
                    <FiltrosSticky icono="mdi-filter-settings">
                        <v-row justify="center">
                            <v-col cols="12" md="6">
                                <v-select 
                                    v-model="filtros.anio"
                                    outlined 
                                    hide-details 
                                    clearable
                                    :items="aniosFiscales.data"
                                    :loading="aniosFiscales.isLoading"
                                    :disabled="procesos.isLoading"
                                    item-value="id"
                                    item-text="anio"
                                    label="Año"
                                    placeholder="Seleccione un año"
                                />
                            </v-col>
                            <v-col cols="12" md="6">
                                <InstitucionesAutoComplete
                                    :value.sync="filtros.institucion"
                                    :evento-reinicio="eventoRestablecerFiltros"
                                    :listener="institucionesListener"
                                    :autocomplete-props="{ disabled: procesos.isLoading }"
                                />
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-autocomplete
                                    v-model="filtros.modalidad"
                                    :items="modalidadesFiltradas"
                                    :loading="cargandoModalidades"
                                    :disabled="procesos.isLoading"
                                    label="Modalidad de compra"
                                    placeholder="Seleccione una modalidad"
                                    item-value="id"
                                    item-text="nombre"
                                    outlined
                                    clearable
                                    hide-details
                                />
                            </v-col>

                            <v-col cols="12" md="6">
                                <div class="d-flex justify-center align-center flex-wrap-reverse flex-md-nowrap" style="gap: 16px;">
                                    <v-btn 
                                        color="primary"
                                        class="flex-grow-1 flex-shrink-1"
                                        @click.stop="hacerBusquedaConFiltros"
                                        :loading="procesos.isLoading"
                                        x-large
                                    >
                                        Buscar
                                    </v-btn>
                                    <v-btn 
                                        color="primary" 
                                        class="flex-grow-1 flex-shrink-1"
                                        outlined
                                        x-large
                                        @click.stop="restablecerInformacion"
                                        :disabled="procesos.isLoading"
                                    >
                                        Limpiar
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </FiltrosSticky>

                    <MontosModalidades
                        class="mt-4"
                        :evento-busqueda="eventoBusquedaFiltros"
                        :listener="estadisticasListener"
                    />

                    <EstadisticasPorModalidad 
                        class="mt-4" 
                        :evento-busqueda="eventoBusquedaFiltros"
                        :listener="graficaListener"
                    />
                    
                    <DataTableComponent 
                        :headers="headers"
                        :items="procesos.data"
                        class="mt-4"
                        @paginar="manejarPaginacion"
                        :total_registros="totalRegistros"
                        :show_loading="procesos.isLoading"
                        v-models:pagina="paginacion.pagina"
                        v-models:select="paginacion.registrosPorPagina"
                        dense
                        no-gutters
                    >
                        <template v-slot:item.codigo_proceso="{ item }">
                            <span style="display: inline-block; min-width: 125px;">{{ item.codigo_proceso }}</span>
                        </template>
                        <template v-slot:item.institucion="{ item }">
                            <span>
                                <strong>{{ item.Institucion.codigo }} - </strong>
                                {{ item.Institucion.nombre }}
                            </span>
                        </template>
                        <template v-slot:item.monto_planificado="{ item }">
                            {{ toMoneyFormat(item.monto_planificado) }}
                        </template>
                        <template v-slot:item.monto_proyectado="{ item }">
                            {{ toMoneyFormat(item.monto_proyectado) }}
                        </template>
                        <template v-slot:item.monto_adjudicado="{ item }">
                            {{ toMoneyFormat(item.monto_adjudicado) }}
                        </template>
                        <template v-slot:item.monto_contratado="{ item }">
                            {{ toMoneyFormat(item.monto_contratado) }}
                        </template>
                        <template v-slot:item.monto_certificado="{ item }">
                            {{ toMoneyFormat(item.monto_certificado) }}
                        </template>
                    </DataTableComponent>
                    
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
import { createLoadable, createPageable, setLoadableResponse, setPageableResponse, toggleLoadable, togglePageable } from '@/utils/loadable';
import EstadisticasPorModalidad from './EstadisticasPorModalidad.vue';
import DataTableComponent from '@/components/DataTableComponent.vue';
import { InstitucionesAutoComplete } from '@/components/compartidos';
import FiltrosSticky from '../components/FiltrosSticky.vue';
import NavButton from '@/components/utils/NavButton.vue';
import { Listener, Observable } from '@/utils/observable';
import MontosModalidades from './MontosModalidades.vue';
import { toMoneyFormat } from '@/utils/data';

const filtrosIniciales = {
    institucion: null,
    anio: null,
    modalidad: null,
}


export default {
    name: 'ReporteModalidadesProcesos',
    components: { FiltrosSticky, NavButton, DataTableComponent, InstitucionesAutoComplete, MontosModalidades, EstadisticasPorModalidad },
    data: () => ({
        // FILTROS
        cargandoModalidades: false,
        filtros: { ...filtrosIniciales },

        // UI
        institucionesListener: new Listener('InstitucionesListener'),
        estadisticasListener: new Listener('EstadisticasListener'),
        graficaListener: new Listener('GraficaListener'),
        unsubscribers: [],
        eventoRestablecerFiltros: 'RESTABLECER_INFORMACION_REPORTERIA_MODALIDADES_PROCESO',
        eventoBusquedaFiltros: 'BUSCAR_INFORMACION_REPORTERIA_MODALIDADES_PROCESO',

        // DATA FILTROS
        aniosFiscales: createLoadable([]),
        modalidades: [],

        // DATA
        paginacion: {
            pagina: 1,
            registrosPorPagina: 10,
        },
        procesos: createPageable([], 10),
    }),
    computed: {
        headers() {
            return [
                { text: 'Nombre de proceso', value: 'nombre_proceso', align: 'center', sortable: false },
                { text: 'Código', value: 'codigo_proceso', align: 'center', sortable: false },
                { text: 'Institución', value: 'institucion', align: 'center', sortable: false },
                { text: 'Modalidad', value: 'FormaContratacion.nombre', align: 'center', sortable: false },
                { text: 'Monto planificado', value: 'monto_planificado', align: 'center', sortable: false },
                { text: 'Monto proyectado', value: 'monto_proyectado', align: 'center', sortable: false },
                { text: 'Monto certificado', value: 'monto_certificado', align: 'center', sortable: false },
                { text: 'Monto adjudicado', value: 'monto_adjudicado', align: 'center', sortable: false },
                { text: 'Monto contratado', value: 'monto_contratado', align: 'center', sortable: false },
            ];
        },
        totalRegistros() {
            return this.procesos.pagination.total_rows;
        },
        modalidadesFiltradas() {
            return this.modalidades.filter((modalidad) => ![18, 27].includes(modalidad.id));
        },
    },
    methods: {
        toMoneyFormat,
        // PRE CARGA DE FILTROS
        async cargarAniosFiscales() {
            toggleLoadable(this.aniosFiscales);
            const { data } = await this.services.PacProcesos.cargarAniosFiscales();
            setLoadableResponse(this.aniosFiscales, { data: Array.isArray(data) ? data : [] });

            return data;
        },
        async cargarModalidades() {
            try {
                this.cargandoModalidades = true;
                const response = await this.services.Paac.getModalidades({ compra_tradicional: true });
                this.modalidades = response.data?.forma_contratacion ?? [];
            } finally {
                this.cargandoModalidades = false;
            }
        },

        // UI
        restablecerInformacion() {
            this.filtros = { ...filtrosIniciales };
            Observable.emit(this.eventoRestablecerFiltros);
            Observable.emit(this.eventoBusquedaFiltros, {});

            this.paginacion = { pagina: 1, registrosPorPagina: 10 };
            this.cargarProcesos();
        },
        hacerBusquedaConFiltros() {
            this.cargarProcesos();

            Observable.emit(this.eventoBusquedaFiltros, { 
                id_institucion: this.filtros.institucion,
                id_anio_fiscal: this.filtros.anio,
                id_forma_contratacion: this.filtros.modalidad,
            });
        },
        manejarPaginacion(paginacion) {
            const { pagina, cantidad_por_pagina: registrosPorPagina } = paginacion;
            this.paginacion = { pagina, registrosPorPagina }

            this.cargarProcesos();
        },

        // DATA
        async cargarProcesos() {
            const filtros = {
                pagination: true,
                per_page: this.paginacion.registrosPorPagina,
                page: this.paginacion.pagina,
                id_institucion: this.filtros.institucion,
                id_anio_fiscal: this.filtros.anio,
                id_forma_contratacion: this.filtros.modalidad,
            }

            togglePageable(this.procesos);
            const { data, headers } = await this.services.ReporteriaModalidadesCompra.cargarProcesos(filtros);
            setPageableResponse(this.procesos, data, headers);
        },
    },
    created() {
        this.cargarAniosFiscales();
        this.cargarModalidades();
        this.cargarProcesos();

        this.unsubscribers = [
            Observable.getInstance().subscribe(this.eventoRestablecerFiltros, this.institucionesListener),
            Observable.getInstance().subscribe(this.eventoBusquedaFiltros, this.estadisticasListener),
            Observable.getInstance().subscribe(this.eventoBusquedaFiltros, this.graficaListener),
        ]
    },
    beforeDestroy() {
        this.unsubscribers.forEach((unsubscriber) => unsubscriber());
    },
}
</script>